<template>
    <div v-loading="loading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>会员答题</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/game' }">游戏</el-breadcrumb-item>
                <el-breadcrumb-item>游戏详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="course-detail-title" style="">
            <div style="padding:10px">
                <div class="fl-l" style="width: 150px;"><img width="150px" :src="pic" /></div>
                <div class="fl-l margin-l-sm">
                    <p class="title">{{name}}</p>
                </div>
                <div class="clear"></div>
            </div>
        </div>

        <div class="margin-sm">

            <el-tabs v-model="activeName" type="card" @tab-click="change">
                <el-tab-pane label="关卡管理" name="manage" path="manage"></el-tab-pane>
            </el-tabs>
            <router-view></router-view>
        </div>

    </div>
</template>

<script>
import { mapActions } from "vuex";
import { config } from "../../js/config";
export default {
    name: "detail",
    data() {
        return {
            loading: false,
            activeName: 'manage',
            pic: '',
            name: '',
            imageUrl: config.imageUrl,
            id: '',
        };
    },
    methods: {
        ...mapActions('game', ['gameGet']),
        change() { },
        async initData() {
            this.loading = true
            const { data } = await this.gameGet(this.id)
            this.pic = this.imageUrl + data.icon
            this.name = data.level
            this.loading = false

        },
    },
    mounted() {
        this.id = this.$route.params.id
        this.initData()
    }
}
</script>

<style>
.course-detail-title {
    margin: 10px;
    background: #fafafa;
    overflow: hidden;
}

.title {
    font-size: 18px;
    margin-top: 5px;
}

.price {
    margin-top: 45px;
    font-size: 16px;
}
</style>